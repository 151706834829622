import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  // useLayoutEffect, 
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import PixelOverlay, { IPixelOverlayRef } from "../components/FX/PixelOverlay";
import images from "../assets/backgrounds/images";
import randomInt from "../randomInt";

interface IBackFxContext {
  next: () => void;
  //   seq: number;
}

const BackFxContext = createContext<IBackFxContext | undefined>(undefined);

interface IProps {
  backdropClassName?: string;
}
export const BackFxProvider: React.FC<IProps> = ({
  children,
  backdropClassName,
}) => {
  const history = useHistory();
  const pixelFx = useRef<IPixelOverlayRef>(null);
  const [backgroundImage, setBackgroundImage] = useState(
    images[randomInt(0, images.length)]
  );
  const pathname = useRef(history.location.pathname);

  const next = useCallback((nextPathname?: string) => {
    pixelFx.current?.update();
    if (nextPathname) {
      // only change background image when current pathname nor previous pathname match /statement/:id
      if (
        !(
          nextPathname.match(/\/statement\/(\d)/) &&
          pathname.current.match(/\/statement\/(\d)/)
        ) &&
        nextPathname !== pathname.current
      ) {
        setBackgroundImage(images[randomInt(images.length)]);
      }
      pathname.current = nextPathname;
    } else {
      setBackgroundImage(images[randomInt(images.length)]);
    }
  }, []);

  useEffect(() => {
    return history.listen(({ pathname }) => {
      next(pathname);
    });
  }, [next, history]);

  return (
    <BackFxContext.Provider value={{ next }}>
      <PixelOverlay
        className={backdropClassName}
        ref={pixelFx}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />
      {children}
    </BackFxContext.Provider>
  );
};

export const useBackFxContext = () => useContext(BackFxContext)!;
