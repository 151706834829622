import { useState, useEffect } from "react";
import {
  OptionalIPopup,
  // PopupId,
  // usePopupsContext,
} from "../context/PopupsContext";
import { useAuthUserContext } from "../context/UserContext";

const usePopup = (): OptionalIPopup => {
  const { API } = useAuthUserContext();
  //const { popups } = usePopupsContext();
  const [popup, setPopup] = useState<OptionalIPopup>();

  useEffect(() => {
    API &&
      API.get(`/popups`).then((res) => setPopup(res.data[0]));
  }, [API]);

  // useEffect(() => {
  //   setPopup(popups?.[0]);
  // }, [popups]); 

  return popup;
};

export default usePopup;
