import React, { useEffect, useState } from "react";
import { IStatement } from "../../context/StatementsContext";
import { IComment, useSubmitComment } from "../../services/ReactionService";
import styles from "./CommentAdd.module.css";
import cx from "classnames";

interface IProps {
  statement: IStatement;
  onCommentAdded?: (comment: IComment) => void;
}

const CommentAdd: React.FC<IProps> = ({ statement, onCommentAdded }) => {
  const [text, setText] = useState<string>();
  //const [inFocus, setInFocus] = useState(false);
  const { comment, submit } = useSubmitComment(statement.id);

  const doSubmit = () => {
    if (text) {
      submit(text);
    }
  };

  useEffect(() => {
    if (comment && comment !== undefined && comment.comment !== "" && comment.comment === text) {
      onCommentAdded && onCommentAdded(comment);
      setText("");
    }
  }, [comment, onCommentAdded, text]);

  return (
    <div className={cx(styles.CommentAdd, { [styles.InFocus]: false })}>
      <textarea
        value={text}
        onChange={(evt) => setText(evt.target.value)}
        //onFocus={() => setInFocus(true)}
        //onBlur={() => setInFocus(false)}
        placeholder="Your Comment"
      />
      <button disabled={!text} onClick={() => doSubmit()}>
        Add Comment
      </button>
    </div>
  );
};

export default CommentAdd;
