import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  TouchEvent,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import styles from "./StatementPage.module.css";
import classnames from "classnames";
import CommentsListing from "./CommentsListing";
import { useManifestoContext } from "../../context/ManifestoContext";
import { IActionBar, useMenuContext } from "../../context/MenuContext";
// import useRandomStatement from "../../hooks/useRandomStatement";
import useStatement from "../../hooks/useStatement";
import { useAuthUserContext } from "../../context/UserContext";

import { ReactComponent as AddToManifestoIcon } from "../../assets/icon_addToManifesto.svg";
// import { ReactComponent as EditStatementIcon } from "../../assets/icon_editStatement.svg";
import { ReactComponent as HideStatementIcon } from "../../assets/icon_hideStatement.svg";
// import { ReactComponent as ShareStatementIcon } from "../../assets/icon_shareStatement.svg";
import QuotesIconPath, {
  ReactComponent as QuotesIcon,
} from "../../assets/quotes.svg";
import NextIconPath, {
  ReactComponent as NextIcon,
} from "../../assets/icon_statement_next.svg";
import PrevIconPath, {
  ReactComponent as PrevIcon,
} from "../../assets/icon_statement_prev.svg";
import { ReactComponent as MyManifestoIcon } from "../../assets/icon_manifesto.svg";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";

import { motion, useMotionValue, useMotionTemplate } from "framer-motion";
import Blur from "../FX/Blur";
import ColorShadow from "../FX/ColorShadow";
import {
  IStatement,
  useStatementsContext,
} from "../../context/StatementsContext";
import CommentsButton from "./CommentsButton";
import ShareButton from "../Share/ShareButton";
import { useHash } from "../../hooks/useQuery";
// import useStatementsStack from "../../hooks/useStatementsStack";

enum SwipeMode {
  Left,
  Right,
  None,
}

interface IRouteParams {
  id: string;
}

const StatementPage: React.FC = () => {
  const { id } = useParams<IRouteParams>();
  const statement = useStatement(parseInt(id));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { loggedIn, user } = useAuthUserContext();
  const { stack } = useStatementsContext();
  // const nextStatement = useRandomStatement(parseInt(id));
  // const prevStatement = useRandomStatement(parseInt(id));
  const { next: nextStatement, previous: prevStatement } = stack;
  const hashComments = useHash("comments");
  const [showComments, setShowComments] = useState(hashComments === "true");
  const {
    add: addToManifesto,
    contains: manifestoContains,
  } = useManifestoContext();
  const { setActionBar } = useMenuContext();
  const clipXLeft = useMotionValue(1000);
  const clipXRight = useMotionValue(0);
  const centerClipPath = useMotionTemplate`polygon(${clipXRight}px 0, ${clipXLeft}px 0, ${clipXLeft}px 100%, ${clipXRight}px 100%)`;
  const rightClipPath = useMotionTemplate`polygon(100% 0, ${clipXLeft}px 0, ${clipXLeft}px 100%, 100% 100%)`;
  const leftClipPath = useMotionTemplate`polygon(0 0, ${clipXRight}px 0, ${clipXRight}px 100%, 0 100%)`;
  // const [isSwiping, setSwiping] = useState(false);
  const [swipeMode, setSwipeMode] = useState(SwipeMode.None);
  const swipeTouchTarget = useRef<HTMLDivElement>(null);
  const swipePos = useMotionValue(0);
  const swipeTranslate = useMotionTemplate`translateX(${swipePos}px)`;
  const history = useHistory();

  const navToStatement = useCallback(
    (statement: IStatement, isPrevious?: boolean) => {
      history.replace(`/statement/${statement.id}`);
      setSwipeMode(SwipeMode.None);
      clipXLeft.set(swipeTouchTarget.current?.clientWidth || 1000);
      clipXRight.set(0);
      return false;
    },
    [history, clipXLeft, clipXRight]
  );

  const onTouchMove = (evt: TouchEvent) => {
    evt.stopPropagation();
    // evt.preventDefault();
    const touch = (evt as any).touches[0];
    switch (swipeMode) {
      case SwipeMode.Left:
        clipXLeft.set(touch.clientX);
        break;
      case SwipeMode.Right:
        clipXRight.set(touch.clientX);
        break;
    }
    swipePos.set(touch.clientX);
  };

  const halfWidth = () => {
    return (swipeTouchTarget.current?.clientWidth || 480) / 2;
  };

  const checkStartSwipe = (evt?: any) => {
    if (showComments) {
      // setSwiping(false);
      setSwipeMode(SwipeMode.None);
      return;
    }
    swipePos.set(evt.touches[0].clientX);
    // setSwiping(true);
    const half = halfWidth();
    // console.log(half, swipeTouchTarget.current);
    if (evt.touches[0].clientX > half) {
      setSwipeMode(SwipeMode.Left);
      clipXLeft.set(evt.touches[0].clientX);
      clipXRight.set(0);
    } else {
      setSwipeMode(SwipeMode.Right);
      clipXLeft.set(half * 2);
      clipXRight.set(evt.touches[0].clientX);
    }
  };

  const stopSwiping = useCallback(
    (evt?: any) => {
      // setSwiping(false);
      const half = halfWidth();
      if (swipeMode === SwipeMode.Left && swipePos.get() < half) {
        nextStatement && navToStatement(nextStatement!);
      } else if (swipeMode === SwipeMode.Right && swipePos.get() > half) {
        prevStatement && navToStatement(prevStatement!);
      } else {
        setSwipeMode(SwipeMode.None);
        clipXLeft.set(half * 2);
        clipXRight.set(0);
      }
      return false;
    },
    [
      clipXLeft,
      clipXRight,
      navToStatement,
      nextStatement,
      prevStatement,
      swipeMode,
      swipePos,
    ]
  );

  useEffect(() => {
    if (showComments) {
      stopSwiping();
    }
  }, [showComments, stopSwiping]);

  useEffect(() => {
    // setExclude([parseInt(id)]);
    setShowComments(hashComments === "true");
  }, [id, hashComments]);

  useEffect(() => {
    let actionBar: IActionBar | undefined = {};
    if (!showComments && statement) {
      if (loggedIn) {
        if(!statement.deleted){
          if (manifestoContains(statement)) {
            actionBar.main = (
              <>
                <Blur offset={[0, -5]} scale={1.25}>
                  <Link to={`/manifesto/me`}>
                    <MyManifestoIcon />
                  </Link>
                </Blur>
                <label>
                  included
                  <br />
                  in my manifesto
                </label>
              </>
            );
          } else {
            actionBar.main = (
              <>
                <Blur offset={[0, -5]} scale={1.25}>
                  {
                    // eslint-disable-next-line
                    <a onClick={() => addToManifesto(statement)}>
                      <AddToManifestoIcon />
                    </a>
                  }
                </Blur>
                <label>
                  Add Statement
                  <br />
                  to my manifesto
                </label>
              </>
            );
          }
        }else{
          actionBar.main = (
            <>
              <Blur offset={[0, -5]} scale={1.25}>
                {
                  // eslint-disable-next-line
                  
                    <HideStatementIcon />
                }
              </Blur>
              <label>
                This statement is<br/> removed
              </label>
            </>
          );
        }
      } else {
        actionBar.main = (
          <>
            <Blur offset={[0, -5]} scale={1.25}>
              <Link to={`/login?redirect=/statement/${statement.id}`}>
                <AddToManifestoIcon />
              </Link>
            </Blur>
            <label>Login to add</label>
          </>
        );
      }
      if (prevStatement) {
        actionBar.left = (
          <>
            <ColorShadow
              maskFile={PrevIconPath}
              offset={[-5, 0]}
              scale={2}
              blurSize={3}
              opacity={0.33}
            >
              <Blur offset={[-10, 0]} scale={1.2}>
                <Link to={`/statement/${prevStatement.id}`}>
                  <PrevIcon />
                </Link>
              </Blur>
            </ColorShadow>
            <label>previous</label>
          </>
        );
      }
      if (nextStatement) {
        actionBar.right = (
          <>
            <ColorShadow
              maskFile={NextIconPath}
              offset={[5, -0]}
              scale={2}
              blurSize={3}
              opacity={0.33}
            >
              <Blur offset={[10, 0]} scale={1.2}>
                <Link to={`/statement/${nextStatement.id}`}>
                  <NextIcon />
                </Link>
              </Blur>
            </ColorShadow>
            <label>next</label>
          </>
        );
      }
    } else {
      actionBar = undefined;
    }
    setActionBar(actionBar);
    return () => setActionBar();
  }, [
    statement,
    nextStatement,
    prevStatement,
    setActionBar,
    addToManifesto,
    manifestoContains,
    loggedIn,
    showComments,
  ]);

  const renderStatement = (statement: IStatement) => {
    const textLength = statement.text.length;
    const longText = textLength > 240;
    const blur1OffsetY = longText ? 20 : 80;
    return (
      <>
        <div
          className={classnames(styles.Statement, statement.deleted ? styles.DeletedStatement : "", {
            [styles.LongText]: longText,
          })}
        >
          <ColorShadow maskFile={QuotesIconPath} className={styles.QuotesIcon}>
            <Blur
              offset={[20, 20]}
              scale={showComments ? 0 : 0.9}
              opacity={0.5}
            >
              <QuotesIcon />
            </Blur>
          </ColorShadow>

          <Blur
            offset={[40, blur1OffsetY]}
            scale={showComments ? 0 : 1.2}
            opacity={0.5}
          >
            <Blur
              offset={[20, 50]}
              scale={showComments ? 0 : 0.9}
              opacity={0.5}
            >
              {statement.text.replace(new RegExp("(^(\"|')*|(\"|')*$)*", "g"), "")}
            </Blur>
          </Blur>
        </div>
        {(statement.source || statement.sourceLink) && (
          <span className={styles.StatementSource}>
            {statement.sourceLink && (
              <a href={statement.sourceLink} target="_blank" rel="noreferrer">
                {statement.source ? "—"+statement.source : statement.sourceLink}
              </a>
            )}
            {!statement.sourceLink && <>{"—"+statement.source}</>}
          </span>
        )}
        <section className={styles.StatementIcons}>
          {!showComments && (
            <CommentsButton
              statement={statement}
              onClick={() => setShowComments(true)}
              className={styles.CommentsButton}
            />
          )}
          <ShareButton
            text={statement.text}
            className={styles.StatementShare}
            url={document.location.href}
          />
          {showComments && (
            <CloseSvg
              onClick={() => setShowComments(false)}
              className={styles.CloseCommentsButton}
            />
          )}
        </section>
      </>
    );
  };

  return (
    <motion.div
      className={classnames(styles.StatementsContainer)}
      onTouchMove={onTouchMove}
      onTouchStart={checkStartSwipe}
      onTouchEnd={stopSwiping}
    >
      {prevStatement && !showComments && (
        <motion.div
          className={classnames(styles.StatementPage, {
            [styles.ShowComments]: showComments,
          })}
          style={{ clipPath: leftClipPath }}
          transition={{ type: "tween", duration: 0.01 }}
        >
          {renderStatement(prevStatement)}
        </motion.div>
      )}
      {nextStatement && !showComments && (
        <motion.div
          className={classnames(styles.StatementPage, {
            [styles.ShowComments]: showComments,
          })}
          style={{ clipPath: rightClipPath }}
          transition={{ type: "tween", duration: 0.01 }}
        >
          {renderStatement(nextStatement)}
        </motion.div>
      )}
      {statement && (
        <motion.div
          className={classnames(styles.StatementPage, {
            [styles.ShowComments]: showComments,
          })}
          style={
            swipeMode !== SwipeMode.None ? { clipPath: centerClipPath } : {}
          }
          transition={{ type: "tween", duration: 0.01 }}
          ref={swipeTouchTarget}
        >
          {renderStatement(statement)}

          {showComments && <CommentsListing statement={statement} />}
        </motion.div>
      )}
      {swipeMode !== SwipeMode.None && (
        <motion.span
          className={styles.SwipeLine}
          style={{ transform: swipeTranslate }}
        ></motion.span>
      )}
    </motion.div>
  );
};

export default StatementPage;
