import { useCallback, useEffect, useState } from "react";
import { useAuthUserContext, IUser, UserID } from "../context/UserContext";
import { StatementId } from "../context/StatementsContext";

export type CommentId = number;
export interface IComment {
  id: CommentId;
  timestamp: Date;
  userId: UserID;
  user?: IUser;
  statementId: StatementId;
  comment: string;
  deleted: boolean;
}

export const useSubmitComment = (statementId: StatementId) => {
  const [comment, setComment] = useState<IComment>();
  const { API } = useAuthUserContext();

  const submit = useCallback(
    (text: string) => {
      API!
        .post(`statement/${statementId}/comment/add`, {
          comment: text,
        })
        .then((res) => {
          setComment(res.data);
        });
    },
    [API, statementId]
  );

  return { comment, submit };
};

export const useLoadComments = (statementId: StatementId) => {
  const [comments, setComments] = useState<IComment[]>([]);
  const { API } = useAuthUserContext();

  const refresh = useCallback(() => {
    API!.get(`/statement/${statementId}/comments`).then((res) =>
      setComments(
        res.data.map((comment: IComment) => {
          return { ...comment, timestamp: new Date(comment.timestamp) };
        })
      )
    );
  }, [statementId, API]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { comments, refresh };
};


export const useModerateComment = (statementId: StatementId ) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [comments, setComments] = useState<IComment[]>([]);
  const { API } = useAuthUserContext();

  const moderate = useCallback(
    (commentId: Number, deleteStatus: Boolean) => {
      API!
        .post(`statement/${statementId}/comment/moderate`, {
          commentId: commentId,
          deleteStatus: deleteStatus,
        })
        .then((res) => {
          //console.log("useModerate res", res);
          if (res && res.status === 200 && res.statusText === 'OK') {
            // const moderatedComment = comments.filter(comment => comment.id === commentId)[0];
            //console.log(" moderatedComment", comments, moderatedComment);
            //moderatedComment.deleted = deleteStatus;
            //[moderatedComment, comments.filter(comment => comment.id !== commentId) ]
            return true
          }else{
            return false;
          } 
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [API, comments, statementId]
  );

  return { moderate };
};
