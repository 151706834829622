import React from "react";

import styles from "./UserRow.module.css";
import { IUser } from "../../context/UsersContext";
import { Link /*, useHistory */ } from "react-router-dom";
// import CommentsButton from "../Statement/CommentsButton";
// import ShareButton from "../Share/ShareButton";
// import { useManifestoContext } from "../../context/ManifestoContext";
import useModerateUser  from "../../hooks/useModerateUser";
// import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { ReactComponent as DefaultUserIcon } from "../../assets/icon_defaulUser.svg";
import { ReactComponent as ModeratorUserIcon } from "../../assets/icon_moderatorUser.svg";
import { ReactComponent as HideStatementIcon } from "../../assets/icon_hideStatement.svg";
import { ReactComponent as RestoreUserIcon } from "../../assets/icon_restoreUser.svg";
import Avatar from "./../User/Avatar";
import cx from "classnames";

interface IProps {
  user: IUser; 
  textClassName?: string;
  linkToUserPage?: boolean;
  loggedInUserUuid?: string;
}

const UserRow: React.FC<IProps> = ({ user, textClassName=styles.UserText, linkToUserPage}) => {
  //const { remove } = useManifestoContext();
  const { moderate } = useModerateUser();
  // const confirmDelete = () => {
  //   if (window.confirm("Remove this user from your manifesto?")) {
  //     remove(user);
  //   }
  // };
  const toggleUserDeletion = (user:IUser) => {
    moderate(user.uuid, !user.deleted, user.moderator ? true : false)
  }

  const toggleUserModeration = (user:IUser) => {
    moderate(user.uuid, user.deleted ? true : false, !user.moderator)
  }

  return (
      /* <div className={styles.Buttons}>
        <CommentsButton
          user={user}
          className={styles.CommentsButton}
          onClick={() =>
            history.push(`/user/${user.id}#comments=true`)
          }
        />
        <ShareButton className={styles.ShareButton} />
        <CloseIcon
          className={styles.RemoveButton}
          onClick={() => confirmDelete()}
        />
      </div> */
      <Link to={ linkToUserPage ? `/user/${user.uuid}` : "#" } className={ cx(textClassName,  styles.UserRow) }> 
        <Avatar className={styles.Avatar} user={user} />
        <span className={ cx(user.deleted && styles.UserDeleted, styles.Identifier)}>
          {user.name}<br/>{user.email}
        </span>
        <span className={ user.deleted ? cx(styles.button, styles.UserDeleted) : styles.button } onClick={ e => toggleUserModeration(user) }>
          { user.moderator ?  <ModeratorUserIcon /> : <DefaultUserIcon /> }
        </span> 
        <span className={ styles.button } onClick={ e => toggleUserDeletion(user) }>
        { user.deleted ? <RestoreUserIcon /> : <HideStatementIcon />}
        </span>
      </Link>
  );
};

export default UserRow;
