import React, { useState } from "react";
import { useAuthUserContext } from "../../context/UserContext";
import { Link } from "react-router-dom";
import useQuery from "../../hooks/useQuery";

import styles from "./LoginPage.module.css";
import PageHeader from "../App/PageHeader";
import ContentSection from "../App/ContentSection";

const LoginPage: React.FC = () => {
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const { error, login /*, user */ } = useAuthUserContext();
  const redirectUrl = useQuery("redirect");

  const doLogin = async () => {
    //console.log("trying to login", email, password);
    if ( (!email ) || (!password)) {
      return;
    }
    try {
      login(email, password, redirectUrl);
    } catch (ex) {}
  };

  return (
    <div className={styles.LoginPage}>
      <PageHeader>Login</PageHeader>
      <ContentSection>
      {error && <p>{error}</p>}
        <div className={styles.LoginForm}>
          <input
            className={styles.EmailInput}
            placeholder="e-mail"
            value={email}
            onChange={(evt) => setEmail(evt.target.value)}
          />
          <input
            className={styles.PasswordInput}
            placeholder="password"
            value={password}
            type="password"
            onChange={(evt) => setPassword(evt.target.value)}
          />
          <button onClick={doLogin}>Login</button>
          <Link
            className={styles.SignupLink}
            to={`/signup${redirectUrl ? `?redirect=${redirectUrl}` : ""}`}
          >
            Sign Up
          </Link>
          <Link className={styles.ForgotPasswordLink} to={`/password/forgot`}>
            Forgot password?
          </Link>
        </div>
      </ContentSection>
    </div>
  );
};

export default LoginPage;
