import { useCallback, useState } from "react";
import { IStatement, useStatementsContext } from "../context/StatementsContext";
import { useAuthUserContext } from "../context/UserContext";

const useModerateStatement = () => {
  const { setStatements } = useStatementsContext();
  const [statement, setStatement] = useState<IStatement>();
  const { API } = useAuthUserContext();

  const moderate = useCallback(
    (id:string, text: string, source?: string, sourceLink?: string, deleted?: boolean) => {
      API!.post(`/statement/${id}/moderate`, { id, text, source, sourceLink, deleted }).then((res) => {
        const newStatement: IStatement = res.data;
        setStatements((statements: IStatement[]) => {
          //console.log("Statement", statement, statements);
          if (statements) {
            return [...statements.filter(statement => statement.id!==newStatement.id), newStatement];
          } else {
            return [newStatement];
          }
        });
        setStatement(newStatement);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [API, setStatements, statement]
  );

  return { statement, moderate };
};

export default useModerateStatement;
