/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthUserContext } from "../../context/UserContext";
import ContentSection from "../App/ContentSection";
import PageHeader from "../App/PageHeader";
import Avatar from "./Avatar";
import { Redirect } from "react-router-dom";

import styles from "./MyProfilePage.module.css";

const MyProfilePage: React.FC = () => {
  const { user, /*updateMyProfile, error,*/ logout, loggedIn  } = useAuthUserContext();
  const [name, setName] = useState<string | undefined>("");
  const [email, setEmail] = useState<string | undefined>("");

  const doLogout = async () => {
    try {
      logout();
    } catch (ex) {
      console.error(ex);
    }
  };


  useEffect(() => {
    setName(user?.name || "");
    setEmail(user?.email || "");
  }, [user]);


  if (!loggedIn) {
    return <Redirect to="/login" />;
  }
  
  if (!user) {
    return (
      <div className={styles.MyProfilePage}>
        <PageHeader>No user</PageHeader>
      </div>
    );
  }

  return (
    <div className={styles.MyProfilePage}>
      <PageHeader>Edit Account</PageHeader>

      
      <ContentSection>
        {/*<p className={styles.profile_edit_field}>
          <label>Name</label>
          <input
            type="text"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
          />
        </p>
        <p className={styles.profile_edit_field}> 
          <label>Email</label>
          <input
            type="text"
            value={email}
            onChange={(evt) => setEmail(evt.target.value)}
          />
        </p>
        <button onClick={(evt) => updateMyProfile({ name, email })}>
          update
  </button>*/}
        <Avatar user={user} showLink={"/avatarpicker"}/>
        <div className={styles.ButtonBar}>
          <Link to="/avatarpicker" className={styles.AvatarPickerButton}>
            Change your avatar
          </Link>
          <div />
          <button onClick={doLogout} >
            log out
          </button>
        </div>
      </ContentSection>
    </div>
  );
};

export default MyProfilePage;
