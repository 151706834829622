import React, { useEffect, useState } from "react";
import Button from "./Button";
import styles from "./PopUp.module.css";
import usePopup from "../../hooks/usePopup";


const PopUp: React.FC = () => {

  const [active, setActive] = useState<boolean>(false);
  const popup = usePopup();

  // const checkCookie = () => {
  //   let username = getCookie("username");
  //   if (username !== "") {
  //    alert("Welcome again " + username);
  //   } 
  // }
  const getCookie = () => {
    let name = "PopUpSeen=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const setCookie = (message:String) => {
    const d = new Date();
    // const n = new Date();
    d.setTime(d.getTime() + (14 * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = "PopUpSeen=" + message + ";" + expires + ";path=/";
  }


  useEffect(() => {
    if (popup) {
      const cookie = getCookie();
      console.log("cookie", cookie);
      setActive( popup.active && popup.message.replaceAll("\n", "") !== cookie  ? true : false);
    }
  }, [popup]);
  
  return (
    active && popup ? <div className={styles.PopUp}>
    <div className={styles.PopUpBackground}></div>
    <div className={styles.PopUpContent}>
      <p>{popup.message.split("\n").map(line=><>{line}<br/></>)}</p>
      <Button onClick={() => {
        setCookie(popup?.message.replaceAll("\n", ""));
        setActive(false);
      }}><span>Got it  &nbsp;&nbsp;🦜</span></Button>
    </div>
    </div> : null
  );
};

export default PopUp;
