import React, { useEffect } from "react";

import styles from "./Menu.module.css";
import classnames from "classnames";
import { useMenuContext } from "../../context/MenuContext";
import { useAuthUserContext } from "../../context/UserContext";
import { Link, useHistory /*, useRouteMatch */ } from "react-router-dom";
// import Avatar from "../User/Avatar";
// import useRandomStatement from "../../hooks/useRandomStatement";
import { useStatementsContext } from "../../context/StatementsContext";
import { BackFxProvider } from "../../context/BackFxContext";
import cx from "classnames";

interface IProps {
  className?: string;
}

const Menu: React.FC<IProps> = ({ className, children }) => {
  const { showMenu, setShowMenu } = useMenuContext();
  const { loggedIn, user } = useAuthUserContext();
  // const match = useRouteMatch<{ id: string }>("/statement/:id");
  // let curStatementId = undefined;
  // if (match?.params.id) {
  //   curStatementId = parseInt(match.params.id);
  // }
  // const randomStatement = useRandomStatement(curStatementId);
  const { stack } = useStatementsContext();
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      setShowMenu(false);
    });
  }, [history, setShowMenu]);

  return (
    <div
      className={classnames(className, styles.Menu, {
        [styles.Show]: showMenu,
      })}
      // onClick={() => setShowMenu(false)}
    >
    <BackFxProvider backdropClassName={styles.Backdrop}>
      <ul>
        <li>
          <Link to={`/common-manifesto`} style={{width:"61%", top: "8.4%", right: "8%"}}>
            <img src={"/menu/common-manifesto.svg"} 
                  className={cx(styles.ButtonMask, styles.CommonManifestoButton)}  alt="Common Manifesto" />
          </Link>
        </li>
        <li>
          <Link to="/about" style={{width: "30%", top:"12%", left: "2%"}}>
            <img src={"/menu/about.svg"} 
                  className={cx(styles.ButtonMask, styles.AboutButton)}  alt="About"/>
          </Link>
        </li>
        <li>
          <Link to={`/statement/${stack?.current?.id ?? 1}`}  style={{width:"53%", top: "32.8%",left: "3%"}}>
            <img src={"/menu/browse-statements.svg"} 
                  className={cx(styles.ButtonMask, styles.BrowseStatementsButton)}  alt="Browse Statements"  />
          </Link>
        </li>
        {!loggedIn && (
          <li className={styles.AccountMenuItem}>
            <Link to="/login" style={{width:"30%", top: "34%",right: "8%"}}>
              <img src={"/menu/account.svg"}  
                className={cx(styles.ButtonMask, styles.AccountButton)}  alt="Account"/>
            </Link>
          </li>
        )}
        {loggedIn && (
          <>
            <li>
              <Link to="/statement/add" style={{width: "50%", top: "48.8%", right: "8%"}}>
                <img 
                  src={"/menu/contribute-statement.svg"} 
                  className={cx(styles.ButtonMask, styles.ContributeStatementButton)}  
                  alt="Contribute Statement"
                />
              </Link>
            </li>
            <li>
              <Link to="/manifesto/me" style={{width:"52%", top: "64%",left: "0%"}}>
                <img src={"/menu/my-manifesto.svg"} 
                  className={cx(styles.ButtonMask, styles.MyManifestoButton)}  alt="My Manifesto"/>
              </Link>
            </li>
            {/* <li>
              <Link to="/dashboard">Dashboard</Link>
            </li> */}
            <li className={styles.AccountMenuItem}>
              <Link to="/account/me" style={{width:"30%", top: "34%",right: "8%"}}>
                <img src={"/menu/account.svg"}  
                  className={cx(styles.ButtonMask, styles.AccountButton)} alt="Account" />
              </Link>
            </li>
            {/* <li>
              <Link to="/logout">Logout</Link>
            </li> */}
          </>
        )}
        {loggedIn && user && user.moderator && (
           <>
           <li>
             <Link to="/moderate" style={ {width:"50%", top: "80%",right: "8%"} } >
               <img src={"/menu/moderate-content.svg"} 
                  className={cx(styles.ButtonMask, styles.ModerateContentButton)} alt="Moderate Content" /> 
             </Link>
           </li>
           </>
        )}
      </ul>
      </BackFxProvider>
    </div>
  );
};

export default Menu;
